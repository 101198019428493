import { defineStore, acceptHMRUpdate } from 'pinia';
import api from '@/api';
import useRouteApi from '@/hooks/useRouteApi';
import axios from '@/plugins/common/axios';
import { useStoreAppBreadcrumbsIndex } from '@/stores/app/breadcrumbs';
// import router from "@/router";
import { useStoreAppErrorIndex } from '@/stores/app/error';

export const useStoreArticlesShow = defineStore({
    id: 'articles/show',

    state: () => ({
        loaded: false,
        pending: true,
        isRedirect: false,
        data: null,
        pagination: null,
    }),

    getters: {
    //
    },

    actions: {
        fetch(data: any, callback: any) {
            const url = api.articles.show.replace(':id', data.id);
            const params = {};
            if (data.token !== undefined && data.token !== null) {
                params.token = data.token;
            }
            return axios.get(url, params, () => {}, () => {});
        },
        async fetchShow(data: any, callback: any) {
            const self = this;
            self.pending = true;
            // return useRouteApi().article(data, (result: any) => {
            //     self.setData(result.data);
            //     callback(self.data);
            // });
            const url = api.articles.show.replace(':id', data.id);
            const params = {};
            if (data.token !== undefined && data.token !== null) {
                params.token = data.token;
            }
            await axios.get(url, params, (result: any) => {
                self.pending = false;
                self.loaded = true;
                self.data = result.data;
                //useSeo().article(self.data);
                // useTitle(self.data.title);
                if (result.breadcrumbs !== undefined) {
                    //useStoreAppBreadcrumbsIndex().createArticle(self.data, self.data.group);
                }
                if (self.isRedirect) {
                    // router.push(`/articles/${self.data.id}/${self.data.slug}`);
                }
                callback(self.data);
            }, (error: any) => {
                // self.pending = false;
                useStoreAppErrorIndex().setError({
                    code: error.status_code,
                    text: error.message,
                });
                console.log(error);
            });
        },
        setIsRedirect(val: boolean) {
            this.isRedirect = val;
        },
        setData(result: any) {
            this.data = result.data;
            this.loaded = true;
            this.pending = false;
            if (result.breadcrumbs !== undefined) {
                //useStoreAppBreadcrumbsIndex().createArticle(result.data, result.data.group);
            }
        }
    },
});
if (import.meta.hot) {
    import.meta.hot.accept(
        acceptHMRUpdate(useStoreArticlesShow, import.meta.hot),
    );
}
